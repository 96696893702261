import { INavbarConfig } from 'shared/interfaces/';

export const HOME_MENUS: INavbarConfig[] = [
  {
    title: 'Co-Creation',
    id: 'co-creation',
  },
  {
    title: 'Features',
    id: 'features',
  },
  {
    title: 'Our Work',
    id: 'our-work',
  },
  {
    title: 'Technology',
    id: 'technology',
  },
  {
    title: 'News',
    id: 'news',
  },
  {
    title: 'Contact Us',
    id: 'contact-us',
  },
];

export const SERVICE_MENUS: INavbarConfig[] = [
  {
    title: 'Service',
    id: 'service',
  },
  {
    title: 'Features',
    id: 'features',
  },
  {
    title: 'Our Work',
    id: 'our-work',
  },
  {
    title: 'Contact Us',
    id: 'contact-us',
  },
];

export const TECHNOLOGY_MENUS: INavbarConfig[] = [
  {
    title: 'Technology',
    id: 'technology',
  },
  {
    title: 'Selection',
    id: 'selection',
  },
  {
    title: 'High Productivity',
    id: 'productivity',
  },
  {
    title: 'Business Growth',
    id: 'growth',
  },
  {
    title: 'Infrastructure',
    id: 'infrastructure',
  },
  {
    title: 'Tech Blog',
    id: 'blog',
  },
  {
    title: 'Contact Us',
    id: 'contact-us',
  },
];

export const SECURITY_MENUS: INavbarConfig[] = [
  {
    title: 'Information Security',
    id: 'information',
  },
  {
    title: 'Product Security',
    id: 'product',
  },
  {
    title: 'Contact Us',
    id: 'contact-us',
  },
];

export const COMPANY_MENUS: INavbarConfig[] = [
  {
    title: 'Vision/Mission',
    id: 'company',
  },
  {
    title: 'Company Overview',
    id: 'company-profile',
  },
  {
    title: 'Core Values',
    id: 'values',
  },

  {
    title: 'Member Introduction',
    id: 'ceo',
  },
  {
    title: 'Office',
    id: 'office',
  },
  {
    title: 'Contact Us',
    id: 'contact-us',
  },
];
export const QUALITY_MENUS: INavbarConfig[] = [
  {
    title: 'Quality',
    id: 'assurance',
  },
  {
    title: 'Preparation',
    id: 'preparation',
  },
  {
    title: 'Method',
    id: 'method',
  },
  {
    title: 'Team',
    id: 'team',
  },
  {
    title: 'Operation',
    id: 'operation',
  },
  {
    title: 'Incidents',
    id: 'incident',
  },
  {
    title: 'organization',
    id: 'organization',
  },
  {
    title: 'Experience',
    id: 'customer',
  },
  {
    title: 'Contact Us',
    id: 'contact-us',
  },
];

export const CAREER_MENUS: INavbarConfig[] = [
  {
    title: 'Career',
    id: 'career',
  },
  {
    title: 'Vision/Mission',
    id: 'vision-mission',
  },
  {
    title: 'Core Values',
    id: 'core-values',
  },
  {
    title: 'Life at wesionaryTEAM',
    id: 'life-at-wesionary-team',
  },
  {
    title: 'Benefit & welfare',
    id: 'benefit-welfare',
  },
  {
    title: 'Technology stack',
    id: 'technology-stack',
  },
  {
    title: 'Leadership',
    id: 'leadership',
  },
  {
    title: 'Selection process',
    id: 'selection-process',
  },
  {
    title: 'Open positions',
    id: 'open-positions',
  },
];
export const ALLIANCE_MENUS: INavbarConfig[] = [
  {
    title: 'Mission',
    id: 'mission',
  },
  {
    title: 'Ecosystem',
    id: 'ecosystem',
  },
  {
    title: 'Contact',
    id: 'contact',
  },
];
